.headerMain {
  display: flex;
  flex-wrap: wrap;
}

.textImportant {
  font-size: 1.5rem;
}

.headerText {
  /* color */
  display: inline-block;
  color: #fcfcfc;
  text-shadow: 0 0 0px #fff, 0 0 2px #fff, 0 0 5px #6b16e7, 0 0 5px #6b16e7,
    0 0 10px #6b16e7, 0 0 15px #6b16e7;
  /* font */
  font-size: 3.5rem;
  font-weight: 700;
  /* attribute */
  line-height: 1.2;
  letter-spacing: 1px;
  /* animation */
  position: relative;
  top: 0px;

  animation: bounce 2 ease infinite;
  -webkit-animation: bounce 2s ease infinite;
  -moz-animation: bounce 2s ease infinite;
  -o-animation: bounce 2s ease infinite;
}

.freeText {
  /* color */
  display: inline-block;
  color: #fcfcfc;
  text-shadow: 0 0 0px #fff, 0 0 2px #fff, 0 0 5px #6b16e7, 0 0 5px #6b16e7,
    0 0 10px #6b16e7, 0 0 15px #6b16e7;
  /* font */
  font-size: 1.2rem;
  font-weight: 700;
  /* attribute */
  line-height: 1.2;
  letter-spacing: 1px;
  /* animation */
  position: relative;
  top: 0px;
}

@media (max-width: 600px) {
  .headerMain {
    justify-content: center;
  }

  .headerText {
    font-size: 4.5rem;
  }
}

.particles {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
}

@media (min-width: 601px) and (max-width: 1024px) {
  .headerText {
    font-size: 2.2rem;
  }
}

@keyframes bounce {
  0% {
    transform: scale(1) translateY(0);
  }

  10% {
    transform: scale(1.2, 0.6);
  }

  30% {
    transform: scale(0.8, 1.1) translateY(-50px);
  }

  50% {
    transform: scale(1) translateY(0);
  }

  100% {
    transform: translateY(0);
  }
}

.headerText:nth-child(1) {
  animation-delay: 2s;
}

.headerText:nth-child(2) {
  animation-delay: 2.3s;
}

.headerText:nth-child(3) {
  animation-delay: 2.6s;
}

.headerText:nth-child(4) {
  animation-delay: 2.9s;
}

@media (max-width: 600px) {
  .headerText {
    font-size: 22vw;
  }
}

@media (min-width: 601px) and (max-width: 1024px) {
  .headerText {
    font-size: 2.2vw;
  }
}
