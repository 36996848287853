html,
body {
  /* firefox */
  -moz-user-select: none;
  /* Safari */
  -webkit-user-select: none;
  /* IE*/
  -ms-user-select: none;
  /* Standard syntax */
  user-select: none;

  background: #6b16e7;
  padding: 0;
  margin: 0;
  overflow-x: hidden;
  scroll-behavior: smooth;
  font-family: "Noto Sans", -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

@media screen and (max-device-width: 600px) {
  body {
    -webkit-text-size-adjust: 100%;
  }
}
